import React, { useEffect } from "react";
import { Link } from "@reach/router";
import { navigate } from "gatsby";
import DotaznikLayout from "../../../components/nabidka/dotaznik/DotaznikLayout";



const ProKoho = ({userData, updateData}) => {
  let uprava = userData;

  function vybrat(clovek) {
    uprava.proKoho = {
      "otazka": "Pro koho plánujete pohřeb?",
      "odpoved": clovek,
    }

    updateData(uprava);
    navigate("/nabidka/dotaznik/stav-blizkeho");
  }

  // mama
  // tata
  // zena
  // muz
  // sestra
  // bratr
  // babicka
  // deda
  // tchyne
  // tchan
  // pritelkyne
  // pritel
  // teta
  // stryc
  // dcera
  // syn
  // pribuzny
  // pribuzna
  // nespecifikovano

  return (
    <DotaznikLayout phase={1} progress={40}>
      <h1>Pro koho plánujete pohřeb?</h1>
      
      <div className="vyberPohrbu vyberBlizkeho">
        <button onClick={() => vybrat("mama")}>Máma</button>
        <button onClick={() => vybrat("tata")}>Táta</button>
        <button onClick={() => vybrat("zena")}>Manželka</button>
        <button onClick={() => vybrat("muz")}>Manžel</button>
        <button onClick={() => vybrat("sestra")}>Sestra</button>
        <button onClick={() => vybrat("bratr")}>Bratr</button>
        <button onClick={() => vybrat("babicka")}>Babička</button>
        <button onClick={() => vybrat("deda")}>Děda</button>
        <button onClick={() => vybrat("tchyne")}>Tchýně</button>
        <button onClick={() => vybrat("tchan")}>Tchán</button>
        <button onClick={() => vybrat("pritelkyne")}>Přítelkyně</button>
        <button onClick={() => vybrat("pritel")}>Přítel</button>
        <button onClick={() => vybrat("teta")}>Teta</button>
        <button onClick={() => vybrat("stryc")}>Strýc</button>
        <button onClick={() => vybrat("dcera")}>Dcera</button>
        <button onClick={() => vybrat("syn")}>Syn</button>
        <button onClick={() => vybrat("pribuzna")}>Příbuzná</button>
        <button onClick={() => vybrat("pribuzny")}>Příbuzný</button>
        <button onClick={() => vybrat("nespecifikovano")}>Nespecifikováno</button>
      </div>
      <Link to="/nabidka/dotaznik" className="zpet">← Zpět</Link>
    </DotaznikLayout>
  );
};


export default ProKoho;